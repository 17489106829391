<template>
  <v-autocomplete
    v-model="innerValue"
    multiple
    color="primary"
    item-value="id"
    :item-text="item => `${item.firstName} ${item.lastName}`"
    :search-input.sync="searchInput"
    :items="items"
    :menu-props="{ offsetY: true, overflowY: true }"
    :error-messages="errorMessages"
    :label="multiple ? $t('global.employees') : $t('global.employee')"
  >
    <template v-slot:prepend-item v-if="multiple && items.length">
      <v-list-item ripple @click="selectAllItems">
        <v-list-item-action>
          <v-icon :color="innerValue.length > 0 ? 'primary' : ''">{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="body-2 font-weight-bold" :class="innerValue.length ? 'red--text' : null">
            {{ $t("buttons.select_all") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-if="multiple" v-slot:selection="{ item, index }">
      {{ index === 0 ? $tc("global.employee_selected", innerValue.length, { count: innerValue.length }) : "" }}
    </template>
    <template #item="{item, attrs}" class="filter-employee-picker">
      <v-list-item-action v-if="multiple">
        <v-checkbox v-model="attrs.inputValue" hide-details color="primary" />
      </v-list-item-action>
      <v-list-item-avatar size="36" :color="item.profile && item.profile.imageUrl ? 'white' : 'primary'" class="mr-2">
        <v-img v-if="item.profile && item.profile.imageUrl" :src="item.profile.imageUrl" />
        <span v-else class="white--text font-weight-bold caption">
          {{ item.firstName.substring(0, 1).toUpperCase() }}{{ item.lastName.substring(0, 1).toUpperCase() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="filter-employee-title">{{ item.firstName }} {{ item.lastName }}</v-list-item-title>
        <v-list-item-subtitle class="filter-employee-branch">
          {{ $helpers.get(item, "employment.branch.name") || "-" }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: "EmployeePicker",
    props: {
      value: [String, Array],
      items: Array,
      errorMessages: Array,
      multiple: Boolean
    },
    data: vm => ({
      searchInput: null
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      isAllItemsSelected() {
        return this.innerValue.length === this.items.length;
      },
      isSomeItemsSelected() {
        return this.innerValue.length > 0 && !this.isAllItemsSelected;
      },
      icon() {
        if (this.isAllItemsSelected) return "mdi-close-box";
        if (this.isSomeItemsSelected) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
    },
    methods: {
      selectAllItems() {
        this.$nextTick(() => {
          this.innerValue = this.isAllItemsSelected ? [] : this.items.map(({ id }) => id);
        });
      }
    }
  };
</script>
